.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: rgb(7, 6, 59);
}

.main-content {
    flex: 1;
    width: 100%;
}
