  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    font-family: 'Chakra Petch';
    border: 2px solid #133564;
    table-layout: fixed;
  }

  table th{
    padding: 7px !important;
    opacity: 1;
    text-align: center;
    padding-bottom: 10px;
    font-size: 24px;
    color: #26e27f;
    background: #133564
  }

  table thead tr {
    border-spacing: 0 30px;
  }

  table tbody{
    color: #55de9f;
    font-size: 20px;
    text-align: center;
  }

  @media (max-width: 768px) {
    table tbody {
      font-size: 16px;
      display: inline-block;
      overflow: auto;
      width: 334px;
      scrollbar-color: lightgreen white;
    }
  
    table th {
      font-size: 20px;
    }
  }